//@ts-nocheck
import React, { Component } from 'react'
import { Pressable, View, Text } from 'react-native'

import styles from './styles'
import Avatar from '../Avatar'
import STRINGS from '../../utils/strings'

export interface ToastProps {
    toastMsg: string
    leftIcon?: React.ReactNode
    avatarImg?: React.ReactNode
    buttonText?: string
    subText?: string
    rightIcon?: React.ReactNode
    textViewStyle?: object
    titleTextStyle?: object
    detailTextStyle?: object
    containerStyle?: object
    toastMsgStyle?: object
    btnTextStyle?: object
    btnStyle?: object
    avatarStyle?: object
    maxlengthSubText?: number
    maxlengthTitleText?: number
    subTextViewStyle?: object
    onClickAvatar?: () => void
    onPressRightIcon?: () => void
    onPressBtn?: () => void
}

class Toast extends Component<ToastProps> {
    render() {
        const {
            toastMsg,
            leftIcon,
            avatarImg,
            buttonText,
            subText,
            rightIcon,
            containerStyle,
            toastMsgStyle,
            btnTextStyle,
            btnStyle,
            textViewStyle,
            titleTextStyle,
            detailTextStyle,
            avatarStyle,
            maxlengthSubText = 20,
            maxlengthTitleText = 20,
            subTextViewStyle,
            onClickAvatar,
            onPressRightIcon,
            onPressBtn,
        } = this.props

        return (
            <View style={[styles.subView, containerStyle]}>
                <View
                    style={{
                        alignItems: 'center',
                    }}
                >
                    {leftIcon ? (
                        leftIcon
                    ) : avatarImg ? (
                        <Avatar
                            testIDContainer={STRINGS.TOAST_AVATAR}
                            image={avatarImg}
                            edit={false}
                            onPress={() => onClickAvatar && onClickAvatar()}
                            size={32}
                            style={[styles.avatarStyle, avatarStyle]}
                        />
                    ) : null}
                </View>

                <View style={[styles.textView, textViewStyle]}>
                    {!subText && (
                        <Text style={[styles.msgStyle, toastMsgStyle]}>
                            {' '}
                            {toastMsg.length < maxlengthTitleText
                                ? `${toastMsg}`
                                : `${toastMsg.substring(0, maxlengthTitleText)}...`}
                        </Text>
                    )}
                    {subText && (
                        <View style={[styles.subTextView, subTextViewStyle]}>
                            <Text style={[styles.text, titleTextStyle]}>
                                {toastMsg}
                            </Text>
                            <Text style={[styles.subText, detailTextStyle]}>
                                {subText.length < maxlengthSubText
                                    ? `${subText}`
                                    : `${subText.substring(0, maxlengthSubText)}...`}
                            </Text>
                        </View>
                    )}
                </View>
                <>
                    {buttonText ? (
                        <Pressable
                            style={[styles.btnStyle, btnStyle]}
                            testID={STRINGS.TOAST_BTN}
                            onPress={() => onPressBtn && onPressBtn()}
                        >
                            <Text style={[styles.btnTextStyle, btnTextStyle]}>
                                {buttonText}
                            </Text>
                        </Pressable>
                    ) : rightIcon ? (
                        <Pressable
                            testID={STRINGS.TOAST_CLOSE}
                            onPress={() =>
                                onPressRightIcon && onPressRightIcon()
                            }
                            style={{
                                alignSelf: 'flex-start',
                                bottom: 5,
                            }}
                        >
                            {rightIcon}
                        </Pressable>
                    ) : null}
                </>
            </View>
        )
    }
}

export default Toast
