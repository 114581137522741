import React, { Component } from 'react'
import {
    Pressable,
    View,
    Image,
    StyleSheet,
    ViewStyle,
    StyleProp,
} from 'react-native'

import Modal from 'react-native-modal'

import { Typography } from '../Typography/Typography'
import STRINGS_TESTCASE from '../../utils/stringsTestCase'
import COLORS from '../../utils/colors'
import closeIconPng from '../../assets/images/close.png'
import { OutsideClickHandler } from '..'

export interface PopoverProps {
    isVisible?: boolean
    onPressCloseIcon?: () => void
    containerStyle?: StyleProp<ViewStyle>
    title?: string | React.ReactNode
    description?: string | React.ReactNode
    children?: React.ReactNode
    closeIcon?: React.ReactNode
}

interface PopoverState {
    isModalVisible: boolean
}

export class Popover extends Component<PopoverProps, PopoverState> {
    constructor(props: PopoverProps) {
        super(props)
        this.state = {
            isModalVisible: props.isVisible || false,
        }
    }

    render() {
        const {
            isVisible,
            onPressCloseIcon,
            children,
            containerStyle,
            title,
            description,
            closeIcon,
        } = this.props

        {/* Needed to make sure there is gap between close icon and text before RN 0.71 */}
        const gapView = <View style={styles.gapView}></View>

        const _title =
            typeof title === 'string' ? (
                <>
                <Typography variant="2xl" style={styles.txtTitle}>
                    {title}
                </Typography>
                {gapView}
                </>
            ) : (
                <>
                {title}
                {gapView}
                </>
            )

        const _description =
            typeof description === 'string' ? (
                <Typography variant="lg" style={styles.txtDescription}>
                    {description}
                </Typography>
            ) : (
                description
            )

        const _closeIcon = closeIcon ? (
            closeIcon
        ) : (
            <Image
                source={closeIconPng}
                style={[styles.crossIcon]}
            />
        )

        return (
            <Modal
                isVisible={isVisible}
                style={styles.modal}
                onBackdropPress={onPressCloseIcon}
            >
                <View style={[styles.innerContainer, containerStyle]}>
                    <View style={styles.titleContainer}>
                        {title ? _title : null}
                        <Pressable
                            onPress={onPressCloseIcon}
                            testID={
                                STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON
                            }
                            style={styles.iconContainer}
                        >
                            {_closeIcon}
                        </Pressable>
                    </View>
                    <View style={styles.horizontalLine} />
                    {description ? _description : null}
                    {children ? children : null}
                </View>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    modal: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    mainContainer: {
        flex: 1,
        borderTopRightRadius: 24,
    },
    innerContainer: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 32,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: COLORS.COLOR_WHITE,
        paddingHorizontal: 30,
        paddingVertical: 25,
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    gapView: {
        width: 12,
    },
    horizontalLine: {
        height: 1,
        backgroundColor: COLORS.COLOR_GREY,
        marginTop: 15,
        marginHorizontal: -30,
    },
    iconContainer: {
        alignSelf: 'flex-start',
        marginTop: 8,
    },
    crossIcon: {
        width: 14,
        height: 14,
    },
    txtTitle: {
        color: COLORS.COLOR_LIGHTBLACK,
        fontWeight: '700',
        flexShrink: 1,
        marginTop: 0,
        marginBottom: 0,
    },
    txtDescription: {
        marginTop: 15,
        color: COLORS.COLOR_LIGHTBLACK,
    },
    txtFooter: {
        fontSize: 16,
        marginTop: 40,
        color: COLORS.COLOR_MEDIUMBLUE,
        alignSelf: 'center',
        fontWeight: '700',
        marginHorizontal: 15,
    },
})
