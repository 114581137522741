import { StyleSheet } from 'react-native'
import { moderateScale } from '../../utils/screenRatio'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
    smallCardContainer: {
        height: 140,
        width: 140,
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 8,
        alignSelf: 'center',
    },
    mainIconCnt: {
        height: 32,
        width: 32,
        borderRadius: 8,
        position: 'absolute',
        right: 8,
        top: 8,
        backgroundColor: COLORS.COLOR_LIGHTPURPLE,
    },
    icon: {
        tintColor: COLORS.COLOR_PRIMARY_PURPLE,
    },
    imgSmall: {
        height: 110,
        width: 110,
        alignSelf: 'center',
        margin: 12,
    },
    txtViewContainer: {
        alignSelf: 'center',
        width: 140,
    },
    txtTitle: {
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 18,
        marginTop: 10,
        color: COLORS.COLOR_LIGHTBLACK,
    },
    txtDesc: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 18,
        marginTop: 5,
        color: COLORS.COLOR_GRAY,
    },

    //for medium cards
    mediumCardContainer: {
        height: 220,
        width: 170,
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 8,
        alignSelf: 'center',
    },
    imgMedium: {
        height: 100,
        width: 100,
        alignSelf: 'center',
        margin: 20,
    },
    txtTitleMedium: {
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 18,
        color: COLORS.COLOR_LIGHTBLACK,
        marginLeft: 16,
    },
    txtPrice: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 18,
        marginTop: 10,
        color: COLORS.COLOR_LIGHTBLACK,
        marginLeft: 16,
    },
    txtDescMedium: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 18,
        marginTop: 5,
        color: COLORS.COLOR_GRAY,
        marginLeft: 16,
    },

    //for big cards
    bigCardContainer: {
        height: 350,
        width: 300,
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 8,
        alignSelf: 'center',
        resizeMode: 'contain',
        // overflow: 'hidden',
    },
    txtTitleBig: {
        fontSize: 14,
        fontWeight: '700',
        lineHeight: 22,
        color: COLORS.COLOR_WHITE,
        marginLeft: 16,
        marginTop: 175,
    },
    txtDescBig: {
        fontSize: 24,
        fontWeight: '700',
        lineHeight: 32,
        color: COLORS.COLOR_WHITE,
        marginLeft: 16,
        marginTop: 5,
    },
    buttonStyle: {
        width: 110,
        height: 44,
        marginLeft: 16,
        marginTop: 20,
        alignSelf: 'center',
        borderRadius: 8,
        backgroundColor: COLORS.COLOR_WHITE,
    },

    //for task card..
    taskCardContainer: {
        height: 110,
        width: 320,
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 4,
        alignSelf: 'center',
    },
    viewTaskTxtImgCont: {
        flexDirection: 'row',
        height: '100%',
    },
    taskCardTxtCont: {
        flex: 1,
        justifyContent: 'center',
    },
    viewTaskBorderCont: {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        width: 8,
        height: '100%',
        backgroundColor: COLORS.COLOR_PRIMARY_PURPLE,
    },
    txtTaskTitle: {
        fontSize: 16,
        fontWeight: '700',
        lineHeight: 24,
        color: COLORS.COLOR_LIGHTBLACK,
        marginLeft: 16,
    },
    txtTaskDesc: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 22,
        color: COLORS.COLOR_LIGHTBLACK,
        marginLeft: 16,
        marginTop: 3,
    },
    viewChildren: {
        alignSelf: 'center',
        marginRight: 10,
        flex: 0.25,
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        resizeMode: 'cover', // or 'contain' depending on your preference
    },
    shadowProp: {
        shadowOffset: { width: 2, height: 4 },
        shadowColor: COLORS.COLOR_SHADOW,
        shadowOpacity: 0.2,
        shadowRadius: 10,
    },
})
export default styles
