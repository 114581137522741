import React, { useRef } from 'react'

import {
    ActivityIndicator,
    View,
    Text,
    StyleProp,
    ViewStyle,
    TextStyle,
    Pressable,
    PressableProps,
    Platform,
} from 'react-native'

import defaultStyles from './styles'
import LinearGradientComponent from '../LinearGradient'
import { makeRNCompatibleStyleArr } from '../../utils/makeRNCompatibleStyleArr'
import COLORS from '../../utils/colors'
import { pickSingle } from "react-native-document-picker";

export interface ButtonProps extends PressableProps {
    loading?: boolean
    disabled?: boolean
    style?: StyleProp<ViewStyle>
    text?: string
    textStyle?: StyleProp<TextStyle>
    gradientColors?: [string, string]
    activityIndicatorColor?: string
    icon?: React.ReactNode
    iconPosition?: 'left' | 'right'
    testID?: string
    onPress?: () => void
    input?: ButtonInput
}

interface ButtonInput {
    type: 'file'
    onFileChange?: (uri?: string) => void;
    accepts?: string
}

const Button = (props: ButtonProps) => {
    const { input, onPress, testID } = props;
    const inputRef = useRef<HTMLInputElement | null>(null)
    const _gradientColors = [COLORS.COLOR_LIGHTBLUE, COLORS.COLOR_MEDIUMBLUE] as [string, string]
    const _activityIndicatorColor = COLORS.COLOR_LIGHTPINK
    let _color = COLORS.COLOR_WHITE

    const getFileAcceptsType = () => {
        return input?.accepts ? input.accepts : "*"
    }

    const loadFileWeb = async (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (e?.target?.files?.length) {
          const url = URL.createObjectURL(e.target.files[0]);
          input?.onFileChange?.(url);
        }
    }

    const _onPress = async () => {
        if (input?.type != "file" ) {
            return onPress?.()
        }
        if (Platform.OS == "ios" || Platform.OS == "android") {
            const res = await pickSingle({
                type: [getFileAcceptsType()],
            });
            input?.onFileChange?.(res?.uri)
            return 
        } 
        inputRef?.current?.click()
    }

    const fileUploader = () => {
        if (input?.type == "file") {
            switch(Platform.OS) {
                case "web" :
                    return (
                        <input
                            data-test-id="uploadFile"
                            id={`${testID}-file-uploader`}
                            type="file"
                            onChange={loadFileWeb}
                            accept={getFileAcceptsType()}
                            hidden
                            ref={inputRef}
                            
                        />
                    )
                default:
                    return null  
            }
            
        }
    }

    const buttonBase = () => {
        const { loading, text, textStyle, icon, iconPosition, testID, activityIndicatorColor } = props

        const finalTextStyle = makeRNCompatibleStyleArr([
            defaultStyles.text,
            { color: _color + `${loading ? '70' : ''}` },
            textStyle,
        ])

        const flexDirection = iconPosition === 'left' ? 'row-reverse' : 'row'
        const _activityIndicatorColorVal =
            activityIndicatorColor || _activityIndicatorColor
        const activityIndicatorTestID = testID
            ? `${testID}-activityIndicator`
            : 'activityIndicator'

        return (
            <View style={[defaultStyles.innerView]}>
                <View
                    style={{
                        display: 'flex',
                        flexDirection,
                        alignItems: 'center',
                    }}
                >
                    <Text style={finalTextStyle}>{text}</Text>
                    {(icon || loading) && (
                        <View
                            style={{
                                marginLeft:
                                    flexDirection === 'row' && text ? 6 : 0,
                                marginRight:
                                    flexDirection === 'row-reverse' && text
                                        ? 6
                                        : 0,
                            }}
                        >
                            {loading ? (
                                <ActivityIndicator
                                    testID={activityIndicatorTestID}
                                    color={_activityIndicatorColorVal}
                                    style={{ paddingLeft: 7 }}
                                />
                            ) : (
                                icon
                            )}
                        </View>
                    )}
                </View>
            </View>
        )
    }

    const buttonWithGradient = (props: ButtonProps) => {
        let {
            children,
            gradientColors,
            disabled,
            loading,
            testID,
            ...restProps
        } = props

        const pressableDisabled = disabled || loading
        const pressableTestID = testID
            ? `${testID}-buttonPressable`
            : 'buttonPressable'
        gradientColors = gradientColors || _gradientColors 

        return (
            <Pressable
                testID={pressableTestID}
                disabled={pressableDisabled}
                {...restProps}
                onPress={_onPress}
            >
                <LinearGradientComponent
                    style={defaultStyles.gradient}
                    colors={gradientColors}
                >
                    {fileUploader()}
                    {buttonBase()}
                </LinearGradientComponent>
            </Pressable>
        )
    }

    const button = (props: ButtonProps) => {
        let { disabled, loading, testID, ...restProps } = props

        const pressableDisabled = disabled || loading
        const pressableTestID = testID
            ? `${testID}-buttonPressable`
            : 'buttonPressable'

        return (
            <Pressable
                testID={pressableTestID}
                disabled={pressableDisabled}
                {...restProps}
                onPress={_onPress}
            >
                {fileUploader()}
                {buttonBase()}
            </Pressable>
        )
    }

    let {
        disabled = false,
        gradientColors,
        style,
        ...restProps
    } = props

    const styleTemporary = { height: 44 }

    const propsWithStyles = {
        disabled,
        gradientColors,
        style: makeRNCompatibleStyleArr([
            {
                ...styleTemporary,
                ...defaultStyles.button,
            },
            style,
            disabled && defaultStyles.disable,
        ]),
        ...restProps,
    }

    if (disabled) {
        _color = COLORS.COLOR_DISABLE_GREY
        gradientColors = null
    }

    if (gradientColors?.length == 2) {
        return buttonWithGradient(propsWithStyles)
    } else {
        return button(propsWithStyles)
    }

}

export default Button