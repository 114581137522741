import React, { Component } from 'react'
import {
    SafeAreaView,
    View,
    FlatList,
    Text,
    Pressable,
    PressableProps,
} from 'react-native'
import styles from './styles'
import COLORS from '../../utils/colors'
import { Toggle } from '../Toggle/Toggle'

export interface ListItemProps extends Omit<PressableProps, 'onPress'> {
    listData: Array<ListDataItem>
    containerStyle?: object
    leftIcon?: React.ReactNode
    textViewStyle?: object
    titleTextStyle?: object
    detailTextStyle?: object
    rightIcon?: React.ReactNode
    switchView?: object
    switchStyle?: object
    flatListView?: object
    onPress?: (item: ListDataItem) => void
}

type ListDataItem = {
    title: string
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
    children?: any
    switch?: boolean
    details?: string
}

export interface ListItemInterface {
    isEnabled: boolean
}

class ListItem extends Component<ListItemProps, ListItemInterface> {
    constructor(props: ListItemProps) {
        super(props)
        this.state = {
            isEnabled: false,
        }
    }

    toggleSwitch = () => {
        this.setState((prevState) => ({
            isEnabled: !prevState.isEnabled,
        }))
    }

    ItemDivider = () => {
        return <View style={styles.itemSeparator} />
    }

    render() {
        const {
            listData,
            flatListView,
            containerStyle,
            textViewStyle,
            titleTextStyle,
            detailTextStyle,
            switchView,
            switchStyle,
            onPress,
            ...otherPressableProps
        } = this.props

        const renderItem = ({ item }: { item: ListDataItem }) => {
            return (
                <Pressable
                    style={[styles.container, containerStyle]}
                    onPress={() => onPress && onPress(item as any)}
                    {...otherPressableProps}
                >
                    {item?.iconLeft}
                    <View style={[styles.textView, textViewStyle]}>
                        <Text style={[styles.text, titleTextStyle]}>
                            {item?.title}
                        </Text>
                        <Text style={[styles.subText, detailTextStyle]}>
                            {item?.details}
                        </Text>
                    </View>
                    {item?.iconRight}
                    {item?.children}
                    <View style={[styles.switch, switchView]}>
                        {item?.switch ? (
                            <Toggle
                                thumbColor={COLORS.COLOR_WHITE}
                                style={[styles.switchStyle, switchStyle]}
                            />
                        ) : null}
                    </View>
                </Pressable>
            )
        }

        return (
            <SafeAreaView>
                <View style={flatListView}>
                    <FlatList
                        data={listData}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={renderItem}
                        ItemSeparatorComponent={this.ItemDivider}
                    />
                </View>
            </SafeAreaView>
        )
    }
}

export default ListItem
