import { StyleSheet } from 'react-native'
import { moderateScale } from '../../utils/screenRatio'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subContainer: {
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        width: 300,
    },
    itemFlatlistContainer: {
        // alignItems: "center",
        // justifyContent: "center",
    },
    pageButton: {
        marginHorizontal: 5,
        padding: 5,
        borderRadius: 5,
    },
    activePageButton: {
        // backgroundColor: 'lightblue',
    },
    pageText: {
        fontSize: moderateScale(8),
        fontWeight: '400',
        color: COLORS.COLOR_LIGHTBLACK,
        textDecorationLine: 'underline',
    },
    ellipsisText: {
        fontSize: moderateScale(8),
        fontWeight: '400',
        color: COLORS.COLOR_LIGHTBLACK,
    },
    activePageText: {
        fontSize: moderateScale(8),
        fontWeight: '700',
        color: COLORS.COLOR_HIGHLIGHTBLUE,
    },
    totalTextView: {
        justifyContent: 'flex-end',
    },
    totalItemtext: {
        fontSize: moderateScale(8),
        fontWeight: '400',
        color: COLORS.COLOR_GRAY,
    },
    icon: {
        width: 22,
        height: 22,
        tintColor: COLORS.COLOR_DISABLE_GREY,
    },
    leftIconContainer: {
        // marginRight:10
    },
    rightIconContainer: {
        marginRight: 20,
    },
})

export default styles
