import { StyleSheet } from 'react-native'

const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value

export const styles = StyleSheet.create({
    container: {
        display: 'flex',
        maxWidth: '100%',
        alignSelf: 'flex-start',
    },
    innerContainer: {
        alignSelf: 'flex-start',
        flexDirection: 'row',
        height: hs(54),
        backgroundColor: 'white',
        borderRadius: ms(6),
        zIndex: 5,
        maxWidth: '100%',
        alignItems: 'center',
    },
    leftContainer: {
        height: hs(42),
        width: ws(42),
        borderRadius: ms(6),
        marginHorizontal: ws(6),
        backgroundColor: '#F0E5FF',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    centerContainer: {
        height: '100%',
        marginHorizontal: ws(12),
        marginLeft: 6,
        paddingVertical: hs(4),
        flexWrap: 'wrap',
        flexShrink: 1,
        justifyContent: 'center',
    },
    rightContainer: {
        marginHorizontal: 4,
    },
    title: {
        fontSize: ms(12),
        fontWeight: '400',
        color: '#000',
        width: '100%',
        marginBottom: 3,
    },
    subTitle: {
        fontSize: ms(12),
        fontWeight: '400',
        color: '#64748B',
    },
    imageStyle: {
        height: '100%',
        width: '100%',
        resizeMode: 'stretch',
        overflow: 'hidden',
    },
    rightIconStyle: {
        height: '100%',
        width: '100%',
        resizeMode: 'stretch',
        overflow: 'hidden',
    },
    iconStyle: {
        height: hs(18),
        width: ws(18),
        overflow: 'hidden',
        resizeMode: 'stretch',
    },
    progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: 2,
        width: '0%',
        backgroundColor: '#8833FF',
        zIndex: 1,
    },
    shadow: {
        shadowOffset: { width: 1, height: 2 },
        shadowColor: '#171717',
        shadowOpacity: 0.2,
        shadowRadius: 10,
    },
})
