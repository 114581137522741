import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
    containerStyle: {
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 8,
        padding: 16,
    },
    productImage: {
        paddingHorizontal: 10,
    },
    productDetailContainer: {
        paddingTop: 8,
    },
    titleTextStyle: {
        fontSize: 14,
        fontWeight: '700',
        lineHeight: 22,
        color: COLORS.COLOR_LIGHTBLACK,
    },
    priceTextStyle: {
        paddingTop: 8,
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 22,
        color: COLORS.COLOR_LIGHTBLACK,
    },
    descriptionTextStyle: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 22,
        color: COLORS.COLOR_GRAY,
    },
    shadowProp: {
        shadowOffset: { width: 2, height: 4 },
        shadowColor: COLORS.COLOR_SHADOW,
        shadowOpacity: 0.2,
        shadowRadius: 10,
    },

    // Loading state
    topRightIconLoading: {
        alignSelf: 'flex-end',
        width: 40,
        height: 40,
        backgroundColor: COLORS.COLOR_LIGHTPURPLE,
        borderRadius: 8,
        position: 'absolute',
        top: 10,
        zIndex: 1,
    },
    productImageLoading: {
        width: '100%',
        height: 155,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    titleLoading: {
        width: '70%',
        padding: 10,
        backgroundColor: COLORS.COLOR_LIGHTGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    descriptionLoading: {
        width: '100%',
        padding: 10,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    descriptionLoadingSmall: {
        maxWidth: 180,
    },
    priceLoading: {
        width: '40%',
        padding: 10,
        backgroundColor: COLORS.COLOR_LIGHTGRAY,
        borderRadius: 8,
        marginTop: 15,
    },

    // Mobile
    containerStyleMobile: {
        maxWidth: 160,
        height: 'fit',
        backgroundColor: COLORS.COLOR_WHITE,
        borderRadius: 8,
        padding: 16,
    },
    productImageMobile: {},
    productDetailContainerMobile: {
        paddingTop: 8,
    },
    gradientOverlay: {
        height: '100%',
    },

    // Generic
    spaceBetween: {
        justifyContent: 'space-between',
    },
    rowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
})

const bigCardStyles = StyleSheet.create({
    bigCardContainerStyle: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    bigCardImageBackgroundStyle: {
        flex: 1,
    },
    bigCardTitleStyle: {
        fontSize: 30,
        color: 'white',
        fontWeight: 'bold',
        paddingTop: 4,
        flexShrink: 1,
    },
    bigCardLabelStyle: {
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
    },
    bigCardContentContainerStyle: {
        justifyContent: 'flex-end',
        flex: 1,
        padding: 16,
    },
    bigCardOnPressStyle: {
        backgroundColor: 'white',
        alignSelf: 'flex-start',
        marginTop: 24,
        paddingVertical: 10,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    bigCardOnPressTextStyle: {
        color: COLORS.COLOR_MEDIUMBLUE,
        fontWeight: 'bold',
    },
})

const taskCardStyles = StyleSheet.create({
    taskCardContainer: {
        minWidth: 327,
        minHeight: 104,
        backgroundColor: 'white',
        borderRadius: 6,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
        borderLeftWidth: 8,
        paddingHorizontal: 20,
        paddingVertical: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    taskCardTitleStyle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    taskCardContentStyle: {
        paddingTop: 5,
    },
    taskImageStyle: {
        height: 32,
        width: 32,
        resizeMode: 'cover',
        position: 'absolute',
        borderRadius: 100,
        borderWidth: 1,
        borderColor: '#F0E5FF',
    },
    overdueContainerStyle: {
        marginTop: 14,
        backgroundColor: '#FEF3C7',
        paddingVertical: 2,
        paddingHorizontal: 6,
        borderRadius: 38,
    },
    overdueTextStyle: {
        fontSize: 12,
        color: '#D97706',
        fontWeight: 'bold',
    },
    taskCardLoadingContainer: {
        width: 327,
        height: 104,
        backgroundColor: 'white',
        borderRadius: 6,
        borderLeftWidth: 8,
        paddingHorizontal: 20,
        paddingVertical: 16,
        borderLeftColor: COLORS.COLOR_PRIMARY_PURPLE,
    },
    taskCardTitleLoading: {
        width: '30%',
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardImageLoading: {
        width: 20,
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardDetailLoading: {
        width: '50%',
        height: 20,
        backgroundColor: COLORS.COLOR_BTNGRAY,
        borderRadius: 8,
        marginTop: 10,
    },
    taskCardOverdueLoading: {
        width: '20%',
        height: 20,
        backgroundColor: COLORS.COLOR_WARNING,
        borderRadius: 8,
        marginTop: 10,
    },
})

export { styles, bigCardStyles, taskCardStyles }
