import React, { useState } from 'react'
import {
    PixelRatio,
    Platform,
    StyleSheet,
    Text,
    TextProps,
    TextStyle,
} from 'react-native'

export { TextProps }

export interface TypographyProps extends TextProps {
    variant?: TextVariant
    component?: SemanticComponent // Defines semantic HTML element
    style?: TextStyle
    children: string | string[]
}

export const Typography = (props: TypographyProps) => {
    const [platform] = useState(Platform.OS)

    const {
        variant = 'base',
        style = {},
        component = 'p',
        ...otherProps
    } = props

    if (platform === 'web') {
        const Component = component as any

        return (
            <Component
                style={{
                    ...webifySizes(styles[variant]),
                    fontFamily: 'Inter',
                    ...style,
                }}
                {...otherProps}
            >
                {props?.children}
            </Component>
        )
    }

    return (
        <Text style={[styles[variant], style]} {...otherProps}>
            {props?.children}
        </Text>
    )
}

const webifySizes = (style) => {
    return Object.keys(style).reduce((acc, key) => {
        acc[key] = ['fontSize', 'lineHeight'].includes(key)
            ? `${style[key]}px`
            : style[key]
        return acc
    }, {})
}

// Make consistency between mobile and web by removing margins of semantic tags
const defaultStyle = {
    marginTop: 0,
    marginBottom: 0,
}

const styles = StyleSheet.create({
    xs: {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 12,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 18,
        textAlign: 'left',
    },
    sm: {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 14,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 22,
        textAlign: 'left',
    },
    base: {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 16,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 24,
        textAlign: 'left',
    },
    lg: {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 18,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 26,
        textAlign: 'left',
    },
    xl: {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 20,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 28,
        textAlign: 'left',
    },
    '2xl': {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 24,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 32,
        textAlign: 'left',
    },
    '3xl': {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 30,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 40,
        textAlign: 'left',
    },
    '4xl': {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 36,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 44,
        textAlign: 'left',
    },
    '5xl': {
        ...defaultStyle,
        fontFamily: 'Arial',
        fontSize: PixelRatio.getFontScale() * 48,
        fontWeight: '400',
        lineHeight: PixelRatio.getFontScale() * 56,
        textAlign: 'left',
    },
})

export type TextVariant =
    | 'xs'
    | 'sm'
    | 'base'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'

export type SemanticComponent =
    | 'p'
    | 'span'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
