import React, { Component } from 'react'
import { Image, Pressable, View, Text } from 'react-native'
import styles from './styles_deprecated'
import Button from '../Button/Button'
import STRINGS from '../../utils/strings'
import STRINGS_TESTCASE from '../../utils/stringsTestCase'
import { Input } from '../Input/Input'
import { Size } from '../../utils/constants'
import { PopoverProps } from '.'

/**
 * @deprecated Since version 1.1.4 - Use Popover instead
 */
export interface PopoverInputComptProps extends PopoverProps {
    /** style for the cancel button */
    btnCancelStyle?: {}
    /** text style for the cancel button */
    txtCancelStyle?: {}
    /** style for the done button */
    btnDoneStyle?: {}
    /** text style for the done button */
    txtDoneStyle?: {}
    /** array of text inputs */
    textInputs?: Array<object>
}

interface PopoverState {
    isModalVisible: boolean
}

class PopoverInputCompt extends Component<
    PopoverInputComptProps,
    PopoverState
> {
    constructor(props: PopoverInputComptProps) {
        super(props)
        this.state = {
            isModalVisible: false,
        }
    }

    // const [name, setName] = useState<string>('');
    // const [emailAddress, setEmailAddress] = useState<string>('');
    // const [modalVisible, setModalVisible] = useState<boolean>(true);

    render() {
        const {
            containerStyle,
            titleText = STRINGS.POPOVER_TITLE,
            titleStyle,
            descriptionText = STRINGS.POPOVER_INPUT_TEXT,
            descriptionStyle,
            btnCancelStyle,
            txtCancelStyle,
            btnDoneStyle,
            txtDoneStyle,
            textInputs,
            closeIcon,
            gradientColor1,
            gradientColor2,
            onPressCloseIcon,
            onPressCancel,
            onPressDone,
        } = this.props

        return (
            <View style={[styles.innerContainer, containerStyle]}>
                <Pressable
                    onPress={onPressCloseIcon}
                    testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
                    style={styles.iconContainer}
                >
                    {closeIcon ? (
                        closeIcon
                    ) : (
                        <Image
                            source={{ uri: 'https://i.imgur.com/amHs6X5.png' }}
                            style={[styles.crossIcon]}
                        />
                    )}
                </Pressable>
                <Text style={[styles.txtTitleInput, titleStyle]}>
                    {titleText}
                </Text>
                <View style={styles.horizontalLine} />
                <Text style={[styles.txtDescriptionInput, descriptionStyle]}>
                    {descriptionText}
                </Text>

                <>
                    {textInputs?.map((item: any, index: number) => (
                        <View key={index} style={styles.inputContainer}>
                            <Input
                                placeholder={item?.placeHolder}
                                label={item?.title}
                                maxLength={100}
                                textStyle={{ fontSize: 16 }}
                                size={Size.Large}
                            />
                        </View>
                    ))}
                </>

                <View style={styles.horizontalLine} />
                <View style={styles.viewBtnContainer}>
                    <Button
                        style={{ ...styles.btnCancelDone, ...btnCancelStyle }}
                        textStyle={[styles.txtCancel, txtCancelStyle]}
                        onPress={onPressCancel}
                        text={STRINGS.CANCEL}
                    />
                    <Button
                        style={btnDoneStyle}
                        textStyle={[styles.txtDelAccount, txtDoneStyle]}
                        onPress={onPressDone}
                        gradientColors={[gradientColor1, gradientColor2]}
                        text={STRINGS.DONE}
                    />
                </View>
            </View>
        )
    }
}

export default PopoverInputCompt
