//@ts-nocheck
import React, { Component } from 'react'
import {
    View,
    ImageBackground,
    Text,
    ViewStyle,
    TextStyle,
    ButtonProps,
} from 'react-native'
import styles from './styles'
import Button from '../Button/Button'
import LinearGradientComponent from '../LinearGradient'
import COLORS from '../../utils/colors'

export interface CardsProps extends ButtonProps {
    size?: 'small' | 'medium' | 'big'
    containerStyle?: ViewStyle
    /** style of the bottom view of the small card */
    lowerContainerStyle?: ViewStyle
    /** style of the icon container */
    iconContainer?: ViewStyle
    /** url of the icon */
    icon?: React.ReactNode
    /** url of the main card image */
    image?: React.ReactNode
    /** style of the main card image */
    imgBackground?: any
    /** to show title on the card */
    title?: string
    /** style of the title text */
    titleTxtStyle?: TextStyle
    /** to show description on the card */
    description?: string
    /** style of the description text */
    descTxtStyle?: TextStyle
    /** to show price on the card */
    price?: string
    /** style of the price text */
    priceTxtStyle?: TextStyle
    /** text to show on the button of the big card */
    btnText?: string
    /** style of the button container on the big card */
    btnStyle?: ViewStyle
    /** color 1 for the gradient of the big card */
    gradientColor1?: string
    /** color 2 for the gradient of the big card */
    gradientColor2?: string
    /** children item views on the task card */
    children?: React.ReactChild
    onPress?: () => void
}

/**
 * @deprecated Since version 1.1.3
 */
class Cards extends Component<CardsProps> {
    render() {
        const {
            size = '',
            containerStyle,
            lowerContainerStyle,
            iconContainer,
            icon,
            image,
            imageBackground = '/',
            title = '',
            titleTxtStyle,
            description = '',
            descTxtStyle,
            price = '',
            priceTxtStyle,
            btnText,
            btnStyle,
            gradientColor1 = '',
            gradientColor2 = '',
            children,
            onPress,
            ...otherButtonProps
        } = this.props

        const smallCard = () => {
            return (
                <>
                    <View
                        style={[
                            styles.smallCardContainer,
                            styles.shadowProp,
                            containerStyle,
                        ]}
                    >
                        {icon && (
                            <Button
                                style={{
                                    ...styles.mainIconCnt,
                                    ...iconContainer,
                                }}
                                icon={icon}
                                textStyle={{ color: COLORS.COLOR_MEDIUMBLUE }}
                                onPress={onPress}
                                {...otherButtonProps}
                            />
                        )}
                        {image}
                    </View>
                    <View
                        style={[styles.txtViewContainer, lowerContainerStyle]}
                    >
                        <Text style={[styles.txtTitle, titleTxtStyle]}>
                            {title}
                        </Text>
                        <Text style={[styles.txtDesc, descTxtStyle]}>
                            {description}
                        </Text>
                    </View>
                </>
            )
        }

        const mediumCard = () => {
            return (
                <View
                    style={[
                        styles.mediumCardContainer,
                        styles.shadowProp,
                        containerStyle,
                    ]}
                >
                    {icon && (
                        <Button
                            style={{ ...styles.mainIconCnt, ...iconContainer }}
                            icon={icon}
                            textStyle={{ color: COLORS.COLOR_MEDIUMBLUE }}
                            onPress={onPress}
                            {...otherButtonProps}
                        />
                    )}
                    {image}
                    <Text style={[styles.txtTitleMedium, titleTxtStyle]}>
                        {title}
                    </Text>
                    <Text style={[styles.txtDescMedium, descTxtStyle]}>
                        {description}
                    </Text>
                    <Text style={[styles.txtPrice, priceTxtStyle]}>
                        {price}
                    </Text>
                </View>
            )
        }

        const bigCard = () => {
            return (
                <ImageBackground
                    source={
                        imageBackground && typeof imageBackground === 'string'
                            ? { uri: imageBackground }
                            : imageBackground
                    }
                    style={[
                        styles.bigCardContainer,
                        styles.shadowProp,
                        containerStyle,
                    ]}
                >
                    <LinearGradientComponent
                        colors={
                            gradientColor1 && gradientColor2
                                ? [gradientColor1, gradientColor2]
                                : [
                                      'rgba(98, 0, 234, 0.00)',
                                      COLORS.COLOR_MEDIUMBLUE,
                                  ]
                        }
                        //@ts-ignore
                        style={{ ...styles.gradientOverlay }}
                    >
                        <View style={{ flex: 1 }}>
                            <Text style={[styles.txtTitleBig, titleTxtStyle]}>
                                {title}
                            </Text>
                            <Text style={[styles.txtDescBig, descTxtStyle]}>
                                {description}
                            </Text>
                            {btnText && (
                                <Button
                                    style={{
                                        ...styles.buttonStyle,
                                        ...btnStyle,
                                    }}
                                    text={btnText}
                                    textStyle={{
                                        color: COLORS.COLOR_MEDIUMBLUE,
                                    }}
                                    onPress={onPress}
                                    {...otherButtonProps}
                                />
                            )}
                        </View>
                    </LinearGradientComponent>
                </ImageBackground>
            )
        }

        const taskCard = () => {
            return (
                <View
                    style={[
                        styles.taskCardContainer,
                        styles.shadowProp,
                        containerStyle,
                    ]}
                >
                    <View style={[styles.viewTaskTxtImgCont]}>
                        <View style={[styles.viewTaskBorderCont]}></View>
                        <View style={[styles.taskCardTxtCont]}>
                            <Text style={[styles.txtTaskTitle, titleTxtStyle]}>
                                {title}
                            </Text>
                            <Text style={[styles.txtTaskDesc, descTxtStyle]}>
                                {description}
                            </Text>
                            <Text style={[styles.txtTaskDesc, priceTxtStyle]}>
                                {price}
                            </Text>
                        </View>
                        <View style={[styles.viewChildren]}>{children}</View>
                    </View>
                </View>
            )
        }

        return size == 'small'
            ? smallCard()
            : size == 'medium'
              ? mediumCard()
              : size == 'big'
                ? bigCard()
                : taskCard()
    }
}

export default Cards
