import { StyleSheet } from 'react-native'
import COLORS from '../../../utils/colors'

export default StyleSheet.create({
    mainView: {
        padding: 20,
    },
    mainContainer: {
        backgroundColor: COLORS.COLOR_WHITE,
        borderWidth: 1,
        borderColor: '#E2E8F0',
        padding: 24,
        marginVertical: 10,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    titleTextStyle: {
        fontSize: 24,
    },
    subView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    detailsTextStyle: {
        fontSize: 16,
        color: '#475569',
    },
    detailViewStyle: {
        marginTop: 10,
        width: 500,
    },
})
