import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    View,
    StyleProp,
    ViewStyle,
    StyleSheet,
    Image,
    SafeAreaView,
    Pressable,
    FlatList,
} from 'react-native'
import DotPagination, {
    DotPaginationProps,
} from '../Pagination/DotPagination/DotPagination'
import leftArrowIcon from '../../assets/images/left-arrow.png'
import rightArrowIcon from '../../assets/images/right-arrow.png'

export interface CarouselProps {
    items: any[]
    maxPageSize: number
    renderItem: (item: any, index: number) => React.ReactNode
    autoPlay?: boolean
    autoPlayIntervalMS?: number
    loop?: boolean
    onIndexChange?: (index: number) => void
    testID?: string
    containerStyle?: StyleProp<ViewStyle>
    contentContainerViewStyle?: StyleProp<ViewStyle>
    paginationType?: 'dots' | 'arrows' | 'both'
    paginationViewStyle?: [StyleProp<ViewStyle>, StyleProp<ViewStyle>]
    paginationArrowIcons?: ArrowIcons
    paginationDotIcon?: DotPaginationProps
}

export interface ArrowIcons {
    arrows: [ArrowIcon, ArrowIcon]
    height: number
    width: number
}

export interface ArrowIcon {
    icon: React.ReactNode
    containerStyle?: StyleProp<ViewStyle>
    onPress?: () => void
}

const Carousel = (props: CarouselProps) => {
    const {
        items,
        renderItem,
        autoPlay,
        autoPlayIntervalMS = 3000,
        containerStyle,
        contentContainerViewStyle,
        paginationType,
        paginationArrowIcons,
        loop,
        testID,
        paginationDotIcon,
        onIndexChange,
        maxPageSize,
    } = props

    const interval = useRef(undefined)
    const scrollViewRef = useRef<FlatList>()

    const [layout, setLayout] = useState(undefined)
    const [cardLayout, setCardLayout] = useState(undefined)
    const [currentScrollIndex, setCurrentScrollIndex] = useState(0)
    const [refreshInterval, setRefreshInterval] = useState(undefined)
    const [pageSize, setPageSize] = useState(undefined)

    useEffect(() => {
        let initialPageSize = items.length
        if (maxPageSize <= items.length) {
            initialPageSize = maxPageSize
        }
        if (
            layout &&
            cardLayout &&
            cardLayout.width * initialPageSize > layout.width
        ) {
            initialPageSize = Math.floor(layout.width / cardLayout.width)
        }
        setPageSize(initialPageSize)
    }, [maxPageSize, layout, cardLayout])

    useEffect(() => {
        if (!autoPlay || !cardLayout) {
            return
        }

        interval.current = setInterval(() => {
            goForward(false)
        }, autoPlayIntervalMS)

        return () => {
            clearInterval(interval.current)
        }
    }, [layout, refreshInterval, currentScrollIndex, cardLayout])

    const goBack = (restartAutoPlay: boolean = true) => {
        let newIndex = currentScrollIndex
        if (currentScrollIndex != 0) {
            newIndex--
        } else if (loop) {
            newIndex = items.length - 1
        }
        if (restartAutoPlay) {
            resetInterval()
        }
        setScrollIndex(newIndex)
    }

    const goForward = (restartAutoPlay: boolean = true) => {
        let newIndex = currentScrollIndex
        if (currentScrollIndex != items.length - pageSize) {
            newIndex = currentScrollIndex + 1
        } else if (loop) {
            newIndex = 0
        }
        if (restartAutoPlay) {
            resetInterval()
        }
        setScrollIndex(newIndex)
    }

    const setScrollIndex = (index: number) => {
        onIndexChange?.(index)
        setCurrentScrollIndex(index)
        scrollViewRef?.current?.scrollToIndex({ animated: true, index })
    }

    const resetInterval = () => {
        clearInterval(interval.current)
        setRefreshInterval(new Date())
    }

    const getArrowPagination = () => {
        const height = paginationArrowIcons?.height
            ? paginationArrowIcons.height
            : styles.arrowIconContainer.height
        const width = paginationArrowIcons?.width
            ? paginationArrowIcons.width
            : styles.arrowIconContainer.width

        const top = layout?.y + layout?.height * 0.5 - height * 0.5
        const firstLeft = layout?.x

        const leftArrowStyle = [
            styles.arrowIconContainer,
            { top: top, left: firstLeft },
            paginationArrowIcons?.arrows[0]?.containerStyle,
        ]
        const rightArrowStyle = [
            styles.arrowIconContainer,
            { left: firstLeft + layout?.width - width, top: top },
            paginationArrowIcons?.arrows[1]?.containerStyle,
        ]

        const onPressLeftIcon = () => {
            paginationArrowIcons?.arrows[0]?.onPress?.()
            goBack()
            resetInterval()
        }

        const onPressRightIcon = () => {
            goForward()
            resetInterval()
            paginationArrowIcons?.arrows[1]?.onPress?.()
        }

        if (paginationArrowIcons) {
            return (
                <>
                    <View style={leftArrowStyle}>
                        <Pressable
                            testID={`${testID}-left-arrow`}
                            onPress={onPressLeftIcon}
                        >
                            {paginationArrowIcons.arrows[0].icon}
                        </Pressable>
                    </View>
                    <View style={rightArrowStyle}>
                        <Pressable
                            testID={`${testID}-right-arrow`}
                            onPress={onPressRightIcon}
                        >
                            {paginationArrowIcons.arrows[1].icon}
                        </Pressable>
                    </View>
                </>
            )
        } else {
            return (
                <>
                    <View style={leftArrowStyle}>
                        <Pressable
                            testID={`${testID}-left-arrow`}
                            onPress={() => {
                                goBack()
                            }}
                        >
                            <Image
                                source={leftArrowIcon}
                                style={styles.arrowIconImage}
                            />
                        </Pressable>
                    </View>
                    <View style={rightArrowStyle}>
                        <Pressable
                            testID={`${testID}-right-arrow`}
                            onPress={() => {
                                goForward()
                            }}
                        >
                            <Image
                                source={rightArrowIcon}
                                style={styles.arrowIconImage}
                            />
                        </Pressable>
                    </View>
                </>
            )
        }
    }

    const getDotPagination = () => {
        return (
            <DotPagination
                testID={testID}
                count={items.length - pageSize}
                selectedIndex={currentScrollIndex}
                onSelected={setScrollIndex}
                {...paginationDotIcon}
            />
        )
    }

    const getPaginationIcons = useMemo(() => {
        switch (paginationType) {
            case 'dots':
                return getDotPagination()
            case 'arrows':
                return getArrowPagination()
            case 'both':
                return (
                    <>
                        {getArrowPagination()}
                        {getDotPagination()}
                    </>
                )
            default:
                return <></>
        }
    }, [paginationType, layout, interval, currentScrollIndex, pageSize])

    return (
        <SafeAreaView>
            <FlatList
                testID={`${testID}-flat-list`}
                style={[styles.container, containerStyle]}
                contentContainerStyle={contentContainerViewStyle}
                ref={scrollViewRef}
                onLayout={(e) => {
                    setLayout(e.nativeEvent.layout)
                }}
                showsHorizontalScrollIndicator={false}
                data={items}
                keyExtractor={(_, index) => `carousel-${index}`}
                getItemLayout={(_, index) => ({
                    length: layout?.width / pageSize,
                    offset: (layout?.width / pageSize) * index,
                    index,
                })}
                horizontal
                snapToAlignment={'center'}
                snapToInterval={layout?.width / pageSize}
                renderItem={({ item, index }) => {
                    if (!layout) {
                        return null
                    }
                    return (
                        <View
                            testID={`${testID}-carousel-item-${index}`}
                            key={`carousel-${index}`}
                            style={styles.carouselItem}
                            onLayout={(e) => {
                                setCardLayout(e.nativeEvent.layout)
                            }}
                        >
                            {renderItem(item, index)}
                        </View>
                    )
                }}
            />
            {layout && getPaginationIcons && getPaginationIcons}
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: { overflow: 'hidden' },
    arrowIconContainer: {
        position: 'absolute',
        height: 30,
        width: 30,
        backgroundColor: 'white',
        borderRadius: 15,
    },
    arrowIconImage: {
        height: 30,
        width: 30,
    },
    carouselItem: { flexDirection: 'column', justifyContent: 'center' },
})

export default Carousel
