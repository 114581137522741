import React, { Component } from 'react'
import { Image, Pressable, View, Text, Platform } from 'react-native'
import { IStep, ISteps } from './interface'
import { styles } from './styles'
import COLORS from '../../utils/colors'
import LinearGradientComponent from '../LinearGradient'

export default class Steps extends Component<ISteps> {
    constructor(props: ISteps) {
        super(props)
    }

    render() {
        const {
            data,
            parentContainer,
            showLine,
            lineSeparatorStyle,
            layout = 'horizontal',
            ...restProps
        } = this.props

        return (
            <View>
                <View
                    style={[
                        layout == 'horizontal'
                            ? styles.parentContainer
                            : styles.parentContainerVertical,
                        parentContainer,
                    ]}
                >
                    {data?.map((item: IStep, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <Step
                                    key={index}
                                    {...item}
                                    index={index}
                                    {...restProps}
                                />
                                {showLine && index !== data.length - 1 && (
                                    <View
                                        style={[
                                            styles.separator,
                                            lineSeparatorStyle,
                                        ]}
                                    />
                                )}
                            </React.Fragment>
                        )
                    })}
                </View>
                {/* {renderContent && renderContent(activeTab)} */}
            </View>
        )
    }
}

export class Step extends Component<ISteps & IStep> {
    constructor(props: ISteps & IStep) {
        super(props)
    }

    render() {
        const {
            step,
            tag,
            doneIcon,
            mainContainer,
            container,
            stepContainer,
            stepStyle,
            activeTagStyle,
            tagStyle,
            gradientColor1,
            gradientColor2,
            activeIndex = 1,
            index,
            onPress,
        } = this.props
        // const stepColor = isCompleted
        //   ? COLORS.COLOR_COMPLETED
        //   : isActive
        //     ? COLORS.COLOR_COOLGRAY
        //     : COLORS.COLOR_GRAY_PLACEHOLDER;

        const doneIconUrl = 'https://i.ibb.co/PDjv4bp/image.png'

        return (
            <Pressable
                style={[styles.mainContainer, mainContainer]}
                onPress={() => onPress(index)}
            >
                <View style={[styles.container, container]}>
                    <View>
                        {index == activeIndex ? (
                            <>
                                {gradientColor1 && gradientColor2 ? (
                                    <LinearGradientComponent
                                        colors={[
                                            gradientColor1,
                                            gradientColor2,
                                        ]}
                                        style={
                                            Platform.OS === 'web'
                                                ? {
                                                      borderRadius: 16,
                                                      height: 32,
                                                      width: 32,
                                                  }
                                                : styles.linearStepContainer
                                        }
                                    >
                                        <View
                                            style={[
                                                styles.linearwebStepContainer,
                                                stepContainer,
                                            ]}
                                        >
                                            {doneIcon || (
                                                <Image
                                                    source={{
                                                        uri: doneIconUrl,
                                                    }}
                                                    style={[styles.imageStyle]}
                                                />
                                            )}
                                        </View>
                                    </LinearGradientComponent>
                                ) : (
                                    <View
                                        style={[
                                            styles.stepContainer,
                                            {
                                                backgroundColor:
                                                    COLORS.COLOR_COMPLETED,
                                            },
                                            stepContainer,
                                        ]}
                                    >
                                        {doneIcon || (
                                            <Image
                                                source={{ uri: doneIconUrl }}
                                                style={[styles.imageStyle]}
                                            />
                                        )}
                                    </View>
                                )}
                            </>
                        ) : index < activeIndex ? (
                            <View
                                style={[
                                    styles.stepContainer,
                                    {
                                        backgroundColor:
                                            COLORS.COLOR_LIGHTBLACK,
                                    },
                                    stepContainer,
                                ]}
                            >
                                <Text style={[styles.step, stepStyle]}>
                                    {step}
                                </Text>
                            </View>
                        ) : (
                            <View
                                style={[
                                    styles.stepContainer,
                                    {
                                        backgroundColor:
                                            COLORS.COLOR_GRAY_PLACEHOLDER,
                                    },
                                    stepContainer,
                                ]}
                            >
                                <Text style={[styles.step, stepStyle]}>
                                    {step}
                                </Text>
                            </View>
                        )}
                    </View>

                    <Text
                        style={[
                            index <= activeIndex
                                ? styles.activeTag
                                : styles.tag,
                            index <= activeIndex ? activeTagStyle : tagStyle,
                        ]}
                    >
                        {tag}
                    </Text>
                </View>
                {/* {isActive ? <View style={[styles.stepActive, stepActiveStyle]} /> : null} */}
            </Pressable>
        )
    }
}
